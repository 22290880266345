@use '@angular/material' as mat;
@use '_components-theme' as component;
@use 'chip-checkbox-styles';

@include mat.core();
@import 'variables';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --text-inverse: #{$bg-inverse};
  --shadow-dark: #{$shadow};
  --bg-page: #{$bg-page};
  --primary-accent: #{$bg-accent};
  --bg-header: #{$bg-table-header};
  --bg-dark-grey: #{$bg-dark-grey};
}

.unauthorizedForBuilding {
  display: none!important;
}

$primary: mat.define-palette(mat.$blue-palette);
$accent: mat.define-palette(mat.$yellow-palette, A700, A100, A400);

$theme: mat.define-light-theme((
        color: (
                primary: $primary,
                accent: $accent,
        ),
        typography: mat.define-typography-config(
                $font-family: 'Roboto, Helvetica Neue, sans-serif',
        )
));


@include mat.all-component-themes($theme);
@include component.theme($theme);

@include mat.chips-density(-5)

