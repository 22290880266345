@import './variables.scss';

.or-button,
.or-input,
button,
input,
textarea,
select {
  &.or-active {
    .or-icon {
      background-position: center center;
    }
  }
}

.or-button-thumbnail {
  background-size: cover;
  border-radius: 1000em;
}

.or-icon,
.or-button-thumbnail {
  background-color: transparent;
  background-position: top center;
  background-repeat: no-repeat;
  display: inline-block;
  height: $icon-size-small;
  vertical-align: middle;
  width: $icon-size-small;

  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }

  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }

  &.or-medium {
    width: $icon-size-medium;
    height: $icon-size-medium;
  }

  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }

  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }

  &.or-inverse {
    background-position: center center;
  }

  &.or-inverse2 {
    background-position: bottom center;
  }

  &.or-active {
    background-position: bottom center;
  }

  & + .or-button-label {
    padding-left: 4px;
  }
}

.or-icon-building {
  &.or-small {
    background-image: url($path-icons + '/24.building.svg');
  }
}

.or-icon-chart {
  &.or-tiny {
    background-image: url($path-icons + '/16.chart.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.chart.svg');
  }
}

.or-icon-daylight-change {
  &.or-small {
    background-image: url($path-icons + '/24.daylight-change.svg');
  }
}

.or-icon-eye {
  &.or-small {
    background-image: url($path-icons + '/24.eye.svg');
  }
}

.or-icon-floor {
  &.or-small {
    background-image: url($path-icons + '/24.floor.svg');
  }
}

.or-icon-glasses {
  &.or-small {
    background-image: url($path-icons + '/24.glasses.svg');
  }
}

.or-icon-floor-heatmap {
  &.or-small {
    background-image: url($path-icons + '/24.floor-heatmap.svg');
  }
}

.or-icon-plug {
  &.or-small {
    background-image: url($path-icons + '/24.plug.svg');
  }
}

.or-icon-beacon {
  &.or-small {
    background-image: url($path-icons + '/24.beacon.svg');
  }
}

.or-icon-lighting-config {
  &.or-small {
    background-image: url($path-icons + '/24.lighting-config.svg');
  }
}

.or-icon-admin {
  &.or-small {
    background-image: url($path-icons + '/24.admin.svg');
  }
}

.or-icon-users {
  &.or-small {
    background-image: url($path-icons + '/24.users.svg');
  }
}

.or-icon-heatmap {
  &.or-small {
    background-image: url($path-icons + '/24.heatmap.svg');
  }
}

.or-icon-info {
  &.or-small {
    background-image: url($path-icons + '/24.info.svg');
  }
}

.or-icon-linkedin {
  &.or-tiny {
    background-image: url($path-icons + '/16.linkedin.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.linkedin.svg');
  }
}

.or-icon-or {
  &.or-tiny {
    background-image: url($path-icons + '/16.or.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.or.svg');
  }
}

.or-icon-twitter {
  &.or-tiny {
    background-image: url($path-icons + '/16.twitter.svg');
  }
}

.or-icon-facebook {
  &.or-tiny {
    background-image: url($path-icons + '/16.facebook.svg');
  }
}

.or-icon-plus {
  &.or-small {
    background-image: url($path-icons + '/24.plus.svg');
  }

  &.or-tiny {
    background-image: url($path-icons + '/16.plus.svg');
  }
}

.or-icon-minus {
  &.or-tiny {
    background-image: url($path-icons + '/16.minus.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.minus.svg');
  }
}

.or-icon-fit {
  &.or-small {
    background-image: url($path-icons + '/24.fit.svg');
  }
}

.or-icon-enabled {
  &.or-small {
    background-image: url($path-icons + '/16.checkmark.green.svg');
  }
}

.or-icon-disabled {
  &.or-small {
    background-image: url($path-icons + '/16.cross.red.svg');
  }
}

.or-icon-confirmed {
  &.or-small {
    background-image: url($path-icons + '/24.confirmed.svg');
  }
}

.or-icon-question-mark {
  &.or-small {
    background-image: url($path-icons + '/24.question-mark.svg');
  }
}

.or-icon-node {
  &.or-tiny {
    background-image: url($path-icons + '/16.node.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.node.svg');
  }
}

.or-icon-him84 {
  &.or-tiny {
    background-image: url($path-icons + '/16.him84-node.svg') !important;
  }
  &.or-small {
    background-image: url($path-icons + '/24.him84-node.svg') !important;
  }
}

.or-icon-hcd405 {
  &.or-tiny {
    background-image: url($path-icons + '/16.hcd405-node.svg') !important;
  }
  &.or-small {
    background-image: url($path-icons + '/24.hcd405-node.svg') !important;
  }
}

.or-icon-hcd038 {
  &.or-tiny {
    background-image: url($path-icons + '/16.hcd405-node.svg') !important;
  }
  &.or-small {
    background-image: url($path-icons + '/24.hcd405-node.svg') !important;
  }
}

.or-icon-pin-drop {
  &.or-small {
    background-image: url($path-icons + '/24.pin-drop-white.svg');
  }
}

.or-icon-passive-node {
  &.or-tiny {
    background-image: url($path-icons + '/16.node.svg');
    overflow: hidden;
    border-radius: 40px;
  }

  &.or-small {
    background-image: url($path-icons + '/24.node.svg');
    background-size: 27px;
    overflow: hidden;
    width: 27px;
    height: 27px;

  }
}

.or-icon-p-node {
  &.or-small {
    background-image: url($path-icons + '/24.node.passive.map.svg');
  }
}

.or-icon-s-node {
  &.or-small {
    background-image: url($path-icons + '/24.node.map.svg');
  }
}

.or-icon-node-dt8 {
  &.or-tiny {
    background-image: url($path-icons + '/16.node-dt8.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.node-dt8.svg');
  }
}

.or-icon-driver {
  &.or-small {
    background-image: url($path-icons + '/24.driver.svg');
  }
}

.or-icon-ble-on {
  &.or-small {
    background-image: url($path-icons + '/24.ble-on.svg');
  }
}

.or-icon-ble-off {
  &.or-small {
    background-image: url($path-icons + '/24.ble-off.svg');
  }
}

.or-icon-ble-query {
  &.or-small {
    background-image: url($path-icons + '/24.ble-query.svg');
  }
}

.or-icon-node-mapped {
  &.or-small {
    background-image: url($path-icons + '/24.node-mapped.svg');
  }
}

.or-icon-node-unmapped {
  &.or-small {
    background-image: url($path-icons + '/24.node-unmapped.svg');
  }
}

.or-icon-node-faulty {
  &.or-small {
    background-image: url($path-icons + '/24.node-faulty.svg');
  }
}

.or-icon-node-unmap {
  &.or-small {
    background-image: url($path-icons + '/24.node-unmap.svg');
  }
}

.or-icon-node-alt {
  &.or-small {
    background-image: url($path-icons + '/24.sensor.alternate.svg');
  }
}

.or-icon-plug {
  &.or-small {
    background-image: url($path-icons + '/24.plug.svg');
  }
}

.or-icon-beacon {
  &.or-small {
    background-image: url($path-icons + '/24.beacon.svg');
  }
}

.or-icon-lighting-config {
  &.or-small {
    background-image: url($path-icons + '/24.lighting-config.svg');
  }
}

.or-icon-admin {
  &.or-small {
    background-image: url($path-icons + '/24.admin.svg');
  }
}

.or-icon-mapping {
  content: url($path-icons + '/hi-res.map.nodes.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
}

.or-icon-dashboard {

    &.or-tiny {
      background-image: url($path-icons + '/24.dashboard.svg');
    }
    &.or-small {
      background-image: url($path-icons + '/24.dashboard.svg');
    }
}

.or-icon-tenant {
  &.or-tiny {
    background-image: url($path-icons + '/16.tenant.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.tenant.svg');
  }
}

.or-icon-reload {
  &.or-small {
    background-image: url($path-icons + '/24.reload.svg');
  }
}

.or-icon-tag {
  &.or-tiny {
    background-image: url($path-icons + '/16.tag.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.tag.svg');
  }
}

.or-icon-occupancy {
  &.or-small {
    background-image: url($path-icons + '/24.users.svg');
  }
}

.or-icon-presence {
  &.or-small {
    background-image: url($path-icons + '/24.presence.svg');
  }
}

.or-icon-scene {
  &.or-small {
    background-image: url($path-icons + '/32.scene.svg');
  }
}

.or-icon-users {
  &.or-small {
    background-image: url($path-icons + '/24.users.svg');
  }
}

.or-icon-userpic {
  background-image: url($path-icons + '/300.userpic.svg');
  width: 32px;
  height: 32px;

  &.or-small {
    width: 24px;
    height: 24px;
  }
}

.or-icon-light-bulb, .or-icon-dim-level {
  &.or-small {
    background-image: url($path-icons + '/24.light-bulb.svg');
  }
}

.or-icon-sun, .or-icon-light-level {
  &.or-small {
    background-image: url($path-icons + '/24.sun.svg');
  }
}

.or-icon-energy, .or-icon-energy-consumption {
  &.or-small {
    background-image: url($path-icons + '/24.energy.svg');
  }
}

.or-icon-energy-occupancy {
  &.or-small {
    background-image: url($path-icons + '/24.energy-occupancy.svg');
  }
}

.or-icon-connection-quality {
  &.or-small {
    background-image: url($path-icons + '/24.node.svg');
  }
}

.or-icon-arrow-left {
  &.or-small {
    background-image: url($path-icons + '/24.arrow-left.svg');
  }
}

.or-icon-arrow-right {
  &.or-small {
    background-image: url($path-icons + '/24.arrow-right.svg');
  }
}

.or-icon-arrow-up {
  &.or-tiny {
    background-image: url($path-icons + '/16.arrow-up.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.arrow-up.svg');
  }
}

.or-icon-arrow-down {
  &.or-tiny {
    background-image: url($path-icons + '/16.arrow-down.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.arrow-down.svg');
  }
}

.or-icon-compare {
  &.or-tiny {
    background-image: url($path-icons + '/16.compare.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.compare.svg');
  }
}

.or-icon-gear {
  &.or-tiny {
    background-image: url($path-icons + '/16.gear.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.gear.svg');
  }
}

.or-icon-copy {
  &.or-tiny {
    background-image: url($path-icons + '/16.copy.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.copy.svg');
  }
}

.or-icon-lock {
  &.or-small {
    background-image: url($path-icons + '/24.lock.svg');
  }
}

.or-icon-unlock {
  &.or-small {
    background-image: url($path-icons + '/24.unlock.svg');
  }
}

.or-icon-switch {
  &.or-small {
    background-image: url($path-icons + '/24.switch.svg');
  }
}

.or-icon-camera {
  &.or-small {
    background-image: url($path-icons + '/24.camera.svg');
  }
}

.or-icon-checkmark {
  &.or-small {
    background-image: url($path-icons + '/24.checkmark.svg');
  }

  &.or-tiny {
    background-image: url($path-icons + '/16.checkmark.svg');
  }
}

.or-icon-checkbox {
  &.or-small,
  &.or-unchecked {
    background-image: url($path-icons + '/24.checkbox.svg');
  }

  &.or-checked {
    background-image: url($path-icons + '/24.checkbox-checked.svg');
  }

  &.or-mixed {
    background-image: url($path-icons + '/24.checkbox-mixed.svg');
  }
}

.or-icon-trash {
  &.or-tiny {
    background-image: url($path-icons + '/16.trash.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.trash.svg');
  }
}

.or-icon-search {
  &.or-tiny {
    background-image: url($path-icons + '/16.search.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.search.svg');
  }
}

.or-icon-edit {
  &.or-tiny {
    background-image: url($path-icons + '/16.edit.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.edit.svg');
  }
}

.or-icon-pulse {
  &.or-small {
    background-image: url($path-icons + '/24.pulse.svg');
  }
}

.or-icon-cross {
  &.or-tiny {
    background-image: url($path-icons + '/16.cross.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.cross.svg');
  }
}

.or-icon-sandglass {
  &.or-small {
    background-image: url($path-icons + '/24.sandglass.svg');
  }
}

.or-icon-move {
  &.or-small {
    background-image: url($path-icons + '/24.arrow-cross.svg');
  }
}

.or-icon-undo {
  &.or-small {
    background-image: url($path-icons + '/24.undo.svg');
  }
}

.or-icon-redo {
  &.or-small {
    background-image: url($path-icons + '/24.redo.svg');
  }
}

.or-icon-selection {
  &.or-small {
    background-image: url($path-icons + '/24.selection.svg');
  }
}

.or-icon-selection-add {
  &.or-small {
    background-image: url($path-icons + '/24.selection-add.svg');
  }
}

.or-icon-selection-clear {
  &.or-small {
    background-image: url($path-icons + '/24.selection-clear.svg');
  }
}

.or-icon-selection-all {
  &.or-small {
    background-image: url($path-icons + '/24.selection-all.svg');
  }
}

.or-icon-sort {
  &.or-small {
    background-image: url($path-icons + '/24.sort.svg');
  }
}

.or-icon-sort-inverse {
  &.or-small {
    background-image: url($path-icons + '/24.sort-inverse.svg');
  }
}

.or-icon-fold {
  &.or-tiny {
    background-image: url($path-icons + '/16.fold.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.fold.svg');
  }
}

.or-icon-unfold {
  &.or-tiny {
    background-image: url($path-icons + '/16.unfold.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.unfold.svg');
  }
}

.or-icon-alert {
  &.or-small {
    background-image: url($path-icons + '/24.alert.svg');
  }

  &.or-tiny {
    background-image: url($path-icons + '/16.alert.svg');
  }
}

.or-icon-play {
  &.or-tiny {
    background-image: url($path-icons + '/16.play.svg');
  }
}

.or-icon-play-alternate {
  &.or-tiny {
    background-image: url($path-icons + '/16.play.alternate.svg');
  }
}

.or-icon-pause {
  &.or-tiny {
    background-image: url($path-icons + '/16.pause.svg');
  }
}

.or-icon-pause-alternate {
  &.or-tiny {
    background-image: url($path-icons + '/16.pause.alternate.svg');
  }
}

.or-icon-stop {
  &.or-tiny {
    background-image: url($path-icons + '/16.stop.svg');
  }
}

.or-icon-stop-alternate {
  &.or-tiny {
    background-image: url($path-icons + '/16.stop.alternate.svg');
  }
}

.or-icon-watch {
  &.or-small {
    background-image: url($path-icons + '/24.watch.svg');
  }
}

.or-icon-bell-curve {
  &.or-small {
    background-image: url($path-icons + '/chart-bell-curve.svg');
  }
}

.or-icon-plan {
  &.or-tiny {
    background-image: url($path-icons + '/16.plan.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.plan.svg');
  }
}

.or-icon-scale {
  &.or-small {
    background-image: url($path-icons + '/24.scale.svg');
  }
}

.or-icon-actions {
  &.or-tiny {
    background-image: url($path-icons + '/16.actions.svg');
  }
}

.or-icon-emergency {
  &.or-tiny {
    background-image: url($path-icons + '/24.emergency.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.emergency.svg');
  }
}

.or-icon-calendar {
  &.or-tiny {
    background-image: url($path-icons + '/16.calendar.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.calendar.svg');
  }
}

.or-icon-report {
  &.or-tiny {
    background-image: url($path-icons + '/16.report.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.report.svg');
  }
}

.or-icon-printer {
  &.or-small {
    background-image: url($path-icons + '/24.printer.svg');
  }
}

.or-icon-group {
  &.or-tiny {
    background-image: url($path-icons + '/16.group.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.group.svg');
  }
}

.or-icon-filter {
  &.or-tiny {
    background-image: url($path-icons + '/16.filter.svg');
  }
}

.or-icon-crash-test {
  &.or-tiny {
    background-image: url($path-icons + '/16.crash-test.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.crash-test.svg');
  }
}

.or-icon-stopwatch {
  &.or-tiny {
    background-image: url($path-icons + '/16.stopwatch.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.stopwatch.svg');
  }
}

.or-icon-playlock {
  &.or-tiny {
    background-image: url($path-icons + '/16.play-lock.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.play-lock.svg');
  }
}

.or-icon-playunlock {
  &.or-tiny {
    background-image: url($path-icons + '/16.play-unlock.svg');
  }

  &.or-small {
    background-image: url($path-icons + '/24.play-unlock.svg');
  }
}

.or-icon-deactivate {
  &.or-small {
    background-image: url($path-icons + '/24.deactivate.svg');
  }
}

.or-icon-send {
  &.or-small {
    background-image: url($path-icons + '/24.send.svg');
  }
}

.or-icon-gateway-config {
  &.or-small {
    background-image: url($path-icons + '/24.gateway-config.svg');
  }
}

.or-icon-key {
  &.or-small {
    background-image: url($path-icons + '/24.key.svg');
  }
}

.or-icon-pub-sub {
  background-image: url($path-icons + '/24.pub-sub.svg');
}

.or-icon-node-options {
  background-image: url($path-icons + '/24.node-options.svg');
}

.or-icon-node-reset {
  background-image: url($path-icons + '/24.node-reset.svg');
}

span.or-status-icon,
.or-status-icon {
  cursor: default;
  min-height: $icon-size-small;
  min-width: $icon-size-small;

  &:hover {
    opacity: 0.8;
  }
}

.or-icon-environmental-node {
  content: url($path-icons + '/env-sensor.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
}

.or-icon-temperature {
  content: url($path-icons + '/thermostat.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
}

.or-icon-pressure {
  content: url($path-icons + '/pressure.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
}

.or-icon-humidity {
  content: url($path-icons + '/humidity.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
}

.or-icon-fuji-ambient-light {
  content: url($path-icons + '/fuji-ambient-light.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
}

.or-icon-sound {
  content: url($path-icons + '/sound.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
}

.or-icon-co2 {
  content: url($path-icons + '/co2.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
}


.or-icon-ble-status-on {
  content: url($path-icons + '/bluetooth-connected.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
}

.or-icon-ble-status-off {
  content: url($path-icons + '/bluetooth-disconnected.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
}

.or-icon-ble-status-unknown {
  content: url($path-icons + '/bluetooth-unknown.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
}

.or-icon-energy-saving {
  content: url($path-icons + '/energy-savings.svg');
  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }
  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }
  &.or-X-huge {
    width: $icon-size-2xlarge;
    height: $icon-size-2xlarge;
  }
}

.or-icon-energy-saving-calendar {
  content: url($path-icons + '/energy-saving-calendar.svg');
  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }
  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }
  &.or-X-huge {
    width: $icon-size-2xlarge;
    height: $icon-size-2xlarge;
  }
}

.or-icon-co2-produced2 {
  content: url($path-icons + '/co2-produced.svg');
  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }
  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }
  &.or-X-huge {
    width: $icon-size-2xlarge;
    height: $icon-size-2xlarge;
  }
}

.or-icon-energy-consumption2 {
  content: url($path-icons + '/energy-consumption.svg');
  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }
  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }
  &.or-X-huge {
    width: $icon-size-2xlarge;
    height: $icon-size-2xlarge;
  }
}

.or-icon-energy-intensity {
  content: url($path-icons + '/energy-intensity.svg');
  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }
  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }
  &.or-X-huge {
    width: $icon-size-2xlarge;
    height: $icon-size-2xlarge;
  }
}

.or-icon-energy-year-VS-last-year {
  content: url($path-icons + '/energy-year-VS-last-year.svg');
  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }
  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }
  &.or-X-huge {
    width: $icon-size-2xlarge;
    height: $icon-size-2xlarge;
  }
}

.or-icon-estimated-yearly-energy {
  content: url($path-icons + '/estimated-yearly-energy.svg');
  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }
  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }
  &.or-X-huge {
    width: $icon-size-2xlarge;
    height: $icon-size-2xlarge;
  }
}

.or-icon-unresponsive-node {
  content: url($path-icons + '/unresponsive-node.svg');
  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }
  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }
  &.or-X-huge {
    width: $icon-size-2xlarge;
    height: $icon-size-2xlarge;
  }
}

.or-icon-lamp {
  content: url($path-icons + '/faulty-luminaire.svg');
  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }
  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }
  &.or-X-huge {
    width: $icon-size-2xlarge;
    height: $icon-size-2xlarge;
  }
}

.or-icon-nodes {
  content: url($path-icons + '/node-blue.svg');
  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }
  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }
  &.or-X-huge {
    width: $icon-size-2xlarge;
    height: $icon-size-2xlarge;
  }
}

.or-icon-reset {
  content: url($path-icons + '/reset.svg');
  &.or-small {
    width: $icon-size-small;
    height: $icon-size-small;
  }
  &.or-tiny {
    width: $icon-size-tiny;
    height: $icon-size-tiny;
  }
  &.or-large {
    width: $icon-size-large;
    height: $icon-size-large;
  }
  &.or-huge {
    width: $icon-size-xlarge;
    height: $icon-size-xlarge;
  }
  &.or-X-huge {
    width: $icon-size-2xlarge;
    height: $icon-size-2xlarge;
  }
}