@import 'variables.scss';
@import 'mixins.scss';

* {
  box-sizing: border-box;
}

html {
  background-color: $bg-page;
  color: $text;
  font: 400 13px / 1 sans-serif;
}

body {
  min-height: 100vh;
  position: relative;
  // we must get rid of this padding-bottom to avoid having a scrollbar
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
}

a {
  color: inherit;
  text-decoration: underline;
  transition: color $transition-fast, opacity $transition-fast;
}

.unauthorizedForBuilding {
  display: none;
}

.or-content {
  padding: 10px 40px 20px 40px;
}

.or-content-expanded {
  padding: 5px 10px 0px 10px;
}

.or-content-header {
  padding: 0 5px 20px;

  &:after {
    clear: both;
    content: '';
    display: block;
  }

  h1 {
    font-size: 1.6rem;
    letter-spacing: -0.05em;
    line-height: $icon-size-medium;
  }
}

.or-flex-container {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  height: 100%;

  &.space-around {
    justify-content: space-around;
  }

  &.center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.or-content-body {
  @include clearfix();
}

.or-pull-left {
  float: left;
}

.or-pull-right {
  float: right;
}

.or-inline-items {
  display: inline-block;
  vertical-align: top;
}

.or-inline-item {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;

  &:last-child {
    margin-right: 0 !important;
  }
}

.or-stacked-items {
  display: block;
}

.or-stacked-item {
  display: block;
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 0;
  }

  .or-button {
    width: 100%;
  }
}

.or-logo {
  background-image: url($path-images + '/logo.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 32px;
  width: 180px;
}

.or-portal-logo {
  background-image: url($path-images + '/orp-logo.png');
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 32px;
  width: 220px;
}

.or-portal-logo-vertical {
  background-image: url($path-images + '/orp-logo-vertical.png');
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 120px;
  width: 170px;
}

.or-portal-logo-vertical-inverse {
  background-image: url($path-images + '/orp-logo-vertical-inverse.png');
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 120px;
  width: 170px;
}

.or-active {
  opacity: 1;
}

.authorized {
  transition: all 1s ease-in-out 100ms;
  transition-property: opacity;
  opacity: 1;
}

.unauthorized {
  transition: all 0s;
  transition-property: height;
  transition-property: width;
  transition-property: margin;
  transition-property: padding;
  opacity: 0;
  height: 0;
  width: 0;
  min-width: 0;
  padding: 0;
  margin: 0;
  display: none;
  overflow: hidden;
}

.or-bg-color-wheel-0 {
  background-color: #076bb6;
}

.or-bg-transparent-color-wheel-0 {
  background-color: rgba(#076bb6, 0.8);
}

.or-ripple-color-wheel-0 {
  box-shadow: 0 0 2px 4px rgba(#076bb6, 0.4), 0 0 0 7px rgba(#fff, 0.8), 0 0 4px 10px rgba(#076bb6, 0.4), 0 0 0 13px rgba(#fff, 0.5);
}

.or-bg-color-wheel-1 {
  background-color: #15b470;
}

.or-bg-transparent-color-wheel-1 {
  background-color: rgba(#15b470, 0.8);
}

.or-ripple-color-wheel-1 {
  box-shadow: 0 0 2px 4px rgba(#15b470, 0.4), 0 0 0 7px rgba(#fff, 0.8), 0 0 4px 10px rgba(#15b470, 0.4), 0 0 0 13px rgba(#fff, 0.5);
}

.or-bg-color-wheel-2 {
  background-color: #bc243c;
}

.or-bg-transparent-color-wheel-2 {
  background-color: rgba(#bc243c, 0.8);
}

.or-ripple-color-wheel-2 {
  box-shadow: 0 0 2px 4px rgba(#bc243c, 0.4), 0 0 0 7px rgba(#fff, 0.8), 0 0 4px 10px rgba(#bc243c, 0.4), 0 0 0 13px rgba(#fff, 0.5);
}

.or-bg-color-wheel-3 {
  background-color: #efc050;
}

.or-bg-transparent-color-wheel-3 {
  background-color: rgba(#efc050, 0.8);
}

.or-ripple-color-wheel-3 {
  box-shadow: 0 0 2px 4px rgba(#efc050, 0.4), 0 0 0 7px rgba(#fff, 0.8), 0 0 4px 10px rgba(#efc050, 0.4), 0 0 0 13px rgba(#fff, 0.5);
}

.or-bg-color-wheel-4 {
  background-color: #e15d44;
}

.or-bg-transparent-color-wheel-4 {
  background-color: rgba(#e15d44, 0.8);
}

.or-ripple-color-wheel-4 {
  box-shadow: 0 0 2px 4px rgba(#e15d44, 0.4), 0 0 0 7px rgba(#fff, 0.8), 0 0 4px 10px rgba(#e15d44, 0.4), 0 0 0 13px rgba(#fff, 0.5);
}

.or-bg-color-wheel-5 {
  background-color: #855723;
}

.or-bg-transparent-color-wheel-5 {
  background-color: rgba(#855723, 0.8);
}

.or-ripple-color-wheel-5 {
  box-shadow: 0 0 2px 4px rgba(#855723, 0.4), 0 0 0 7px rgba(#fff, 0.8), 0 0 4px 10px rgba(#855723, 0.4), 0 0 0 13px rgba(#fff, 0.5);
}

.or-bg-color-wheel-6 {
  background-color: #4e6172;
}

.or-bg-transparent-color-wheel-6 {
  background-color: rgba(#4e6172, 0.8);
}

.or-ripple-color-wheel-6 {
  box-shadow: 0 0 2px 4px rgba(#4e6172, 0.4), 0 0 0 7px rgba(#fff, 0.8), 0 0 4px 10px rgba(#4e6172, 0.4), 0 0 0 13px rgba(#fff, 0.5);
}

.or-bg-color-wheel-7 {
  background-color: #c3447a;
}

.or-bg-transparent-color-wheel-7 {
  background-color: rgba(#c3447a, 0.8);
}

.or-ripple-color-wheel-7 {
  box-shadow: 0 0 2px 4px rgba(#c3447a, 0.4), 0 0 0 7px rgba(#fff, 0.8), 0 0 4px 10px rgba(#c3447a, 0.4), 0 0 0 13px rgba(#fff, 0.5);
}

.or-bg-color-wheel-8 {
  background-color: #5b5ea6;
}

.or-bg-transparent-color-wheel-8 {
  background-color: rgba(#5b5ea6, 0.8);
}

.or-ripple-color-wheel-8 {
  box-shadow: 0 0 2px 4px rgba(#5b5ea6, 0.4), 0 0 0 7px rgba(#fff, 0.8), 0 0 4px 10px rgba(#5b5ea6, 0.4), 0 0 0 13px rgba(#fff, 0.5);
}

.or-bg-color-wheel-9 {
  background-color: #507a00;
}

.or-bg-transparent-color-wheel-9 {
  background-color: rgba(#507a00, 0.8);
}

.or-ripple-color-wheel-9 {
  box-shadow: 0 0 2px 4px rgba(#507a00, 0.4), 0 0 0 7px rgba(#fff, 0.8), 0 0 4px 10px rgba(#507a00, 0.4), 0 0 0 13px rgba(#fff, 0.5);
}

.mat-tooltip {
  &.font-15 {
    font-size: 15px;
  }
}

button.example-custom-date-class {
  background: orange;
  border-radius: 100%;
}

.mat-mdc-menu-panel {
  min-width: unset !important;
  max-width: unset !important;
}

// Adding mat-customColor mat-checkbox css to portal.scss to avoid using ::ng-deep in the component.scss file
 .mat-mdc-checkbox.mat-customColor.mat-mdc-checkbox-checked .mdc-checkbox__background {
  background-color: var(--custom-color) !important;
  border-color: var(--custom-color) !important;
}

 .mat-mdc-checkbox.mat-customColor.mat-mdc-checkbox-checked .mdc-checkbox__ripple {
  background-color: var(--custom-color) !important;
}

 .mat-mdc-checkbox.mat-customColor.mat-mdc-checkbox-checked .mdc-checkbox__checkmark-path {
  stroke: white !important; // You can also change this based on your requirements
}

:root {
  --schedule-chip-bg: #61A2D1;
  --floor-chip-bg: #D48799;
  //--tag-chip-bg: #CA91CA;
  //--tag-chip-bg: #FF695C;
  //--tag-chip-bg: #EB5C6A;
  --tag-chip-bg: #E94959;
  --test-type-chip-bg: #9385FF;
  --test-status-chip-bg: #119DA4;
  --date-chip-bg: #7986A4;
  --chip-text-color: white;
}

.mdc-text-field--outlined mat-mdc-form-field-infix {
    padding-top: 8px !important;
    padding-bottom: 10px !important;
}


